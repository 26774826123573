import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/store';
import '@progress/kendo-theme-material/dist/all.css';

/* A new way to render React apps. It is a new API that allows you to render React apps without
unmounting the previous tree. This is useful for server-side rendering, where you want to render the
app on the server, and then hydrate it on the client. */
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
/* Rendering the app. */
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
